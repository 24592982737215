import './AppointmentPage.css';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  checkNotNull,
  error,
  sortedBy,
} from '../../../app-components/common/Utils';
import { api } from '../../../app-components/network/AimsLkApi';
import { CircularProgress } from '@mui/material';
import { AppointmentProtocol } from '../../../models/AppointmentProtocol';
import ContentColumn from '../../components/ContentColumn/ContentColumn';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { AppointmentResource } from '../../../models/AppointmentResource';
import { FormattedProtocol } from '../../../models/FormattedProtocol';

export default function AppointmentPage(): ReactElement {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [protocol, setProtocol] = useState<AppointmentProtocol | null>(null);
  const [resources, setResources] = useState<AppointmentResource[]>([]);
  const [formattedProtocol, setFormattedProtocol] =
    useState<FormattedProtocol | null>(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const dto = await api.getAppointmentProtocol(checkNotNull(id));
      if (dto.type != 'success') error();
      const protocol = checkNotNull(dto.payload.appointment_protocols)[0];
      setProtocol(protocol);
      setFormattedProtocol(checkNotNull(dto.payload.formatted_protocols)[0]);
      setLoading(false);
      const sortedResources = sortedBy(
        dto.payload.appointment_resources ?? [],
        (it) => it.order
      );
      setResources(sortedResources);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTime = useMemo(() => {
    if (protocol?.create_time == null) return '';
    const date = new Date(protocol.create_time);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }, [protocol?.create_time]);

  const onClickDelete = () => {
    if (!id) return;
    const confirmed = confirm(
      'Вы действительно хотите удалить данный прием и все дочерние сущности (протокол, аудио-файлы)?'
    );
    if (confirmed) {
      (async () => {
        await api.deleteAppointment(id);
        navigate('/appointments');
      })();
    }
  };

  // const parsedColumns = useMemo(() => {
  //   if (protocol == null) return [];
  //   let parsedObject: { [key: string]: string } | null = null;
  //   try {
  //     parsedObject = JSON.parse(protocol.summary);
  //   } catch (error) {
  //     // Не удалось спарсить JSON, возможно там не джсон
  //   }
  //   if (parsedObject == null) return [];
  //
  //   const keys = Object.keys(parsedObject);
  //   keys.sort();
  //   return keys.map((key) => ({
  //     name: key,
  //     content: checkNotNull(parsedObject)[key] ?? '',
  //   }));
  // }, [protocol]);

  return (
    <ContentColumn>
      <PageHeader>Прием #{id}</PageHeader>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <div className='appointment-page__create-time'>
            Протокол создан {createTime}
          </div>
          {formattedProtocol && (
            <HandledProtocol protocol={formattedProtocol} />
          )}
          <h2 className='appointment-page__subheader'>Исходные файлы</h2>
          {resources.map((resource) => {
            return <ResourcePlayer key={resource.id} resource={resource} />;
          })}
          <DebugInformation protocol={protocol} onClickDelete={onClickDelete} />
        </>
      )}
    </ContentColumn>
  );
}

function HandledProtocol(props: { protocol: FormattedProtocol }): ReactElement {
  const [clickedIndex, setClickedIndex] = useState(-1);
  const rowClicked = (index: number) => {
    const entry = checkNotNull(props.protocol.entries[index]);
    // noinspection JSIgnoredPromiseFromCall
    navigator.clipboard.writeText(entry.value);
    setClickedIndex(index);
    setTimeout(() => {
      setClickedIndex(-1);
    }, 500);
  };
  return (
    <>
      <h3 className='appointment-page__protocol-header'>
        Направление: {props.protocol.header}
      </h3>
      {props.protocol.entries.map((entry, index) => {
        return (
          <div
            key={entry.title}
            className={`appointment-page__column ${index == clickedIndex ? 'appointment-page__column_clicked' : ''}`}
            onClick={() => rowClicked(index)}
            onKeyDown={() => rowClicked(index)}
            role={'presentation'}
          >
            <div className='appointment-page__column-background' />
            <div className='appointment-page__column-name'>{entry.title}</div>
            <div className='appointment-page__column-value'>{entry.value}</div>
          </div>
        );
      })}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DebugInformation(props: {
  protocol: AppointmentProtocol | null;
  onClickDelete: () => void;
}): ReactElement {
  const [extrasVisible, setExtrasVisible] = useState(false);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className='appointment-page__show-extra-button'
        onClick={() => setExtrasVisible(!extrasVisible)}
      >
        {extrasVisible
          ? 'Скрыть дополнительную информацию'
          : 'Показать дополнительную информацию'}
      </div>
      {extrasVisible && (
        <div>
          {props.protocol && (
            <>
              <h2>Результат транскрибации</h2>
              <div>{props.protocol.transcribed_content}</div>
            </>
          )}
          <button
            className='appointment-page__delete-button'
            onClick={props.onClickDelete}
          >
            Удалить прием
          </button>
          {/*<h2>Summary</h2>*/}
          {/*<div>{props.protocol.summary}</div>*/}
        </div>
      )}
    </>
  );
}

function ResourcePlayer(props: {
  resource: AppointmentResource;
}): ReactElement {
  return (
    <div className={'appointment-page__audio'}>
      <figure>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          controls
          src={props.resource.url}
          className={'appointment-page__audio-controls'}
        />
      </figure>
    </div>
  );
}
