import { createBrowserRouter, Navigate } from 'react-router-dom';
import LoginPage from '../pages/LoginPage/LoginPage';
import AppointmentsPage from '../pages/AppointmentsPage';
import AppointmentPage from '../pages/AppointmentPage/AppointmentPage';
import ErrorPage from '../pages/ErrorPage';
import React, { ReactElement, useContext } from 'react';
import { LoggedUserContext } from '../components/App/LoggedUserContext';

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: (
          <RedirectToAccount>
            <LoginPage />
          </RedirectToAccount>
        ),
      },
      {
        path: 'appointments',
        element: (
          <ProtectedRoute>
            <AppointmentsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'appointment/:id',
        element: (
          <ProtectedRoute>
            <AppointmentPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

/**
 * Редиректим уже гостей на страницу входа
 */
function ProtectedRoute(props: { children: ReactElement }): ReactElement {
  const { loggedUser } = useContext(LoggedUserContext);
  return loggedUser != null ? props.children : <Navigate to='/' />;
}

/**
 * Редиректим уже залогиненых пользователей сразу в ЛК
 */
function RedirectToAccount(props: { children: ReactElement }): ReactElement {
  const { loggedUser } = useContext(LoggedUserContext);
  return loggedUser != null ? <Navigate to='/appointments' /> : props.children;
}
