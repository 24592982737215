export function checkNotNull<T>(item: T | null | undefined): T {
  if (item === null) {
    throw new Error('Item is null');
  }

  if (item === undefined) {
    throw new Error('Item is null');
  }

  return item;
}

export function error(message?: string): never {
  throw new Error(message ?? 'Illegal state error');
}

export function sortedBy<T, I>(items: T[], item: (_: T) => I): T[] {
  return [...items].sort((left, right) => {
    const fieldA = item(left);
    const fieldB = item(right);
    if (fieldA < fieldB) return -1;
    if (fieldA > fieldB) return 1;
    return 0;
  });
}

export function sortedByDescending<T, I>(items: T[], item: (_: T) => I): T[] {
  return sortedBy(items, item).reverse();
}
