import './ContentColumn.css';
import { ReactElement, ReactNode } from 'react';

export default function ContentColumn(props: {
  children: ReactNode;
}): ReactElement {
  return (
    <div className='content-column'>
      <div className='content-column__container'>{props.children}</div>
    </div>
  );
}
