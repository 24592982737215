import { checkNotNull } from '../common/Utils';
import { UnifiedDto } from '../../models/UnifiedDto';
import { ApiResponse } from '../../models/ApiResponse';
import { AuthToken } from '../../models/AuthToken';

class AimsLkApi {
  private domain = 'https://api.aimsclinical.ru';
  private token: string | null = null;

  private async handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
      throw `Non-success response: ${response.status}`;
    }
    return await response.json();
  }

  public setToken(token: string | null) {
    this.token = token;
  }

  async fetchAuthToken(
    username: string,
    password: string
  ): Promise<ApiResponse<AuthToken>> {
    const response = await fetch(
      `${this.domain}/api/v1/auth?username=${username}&password=${password}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return await this.handleResponse(response);
  }

  async getUserInfo(): Promise<ApiResponse<UnifiedDto>> {
    const token = checkNotNull(this.token);
    const response = await fetch(`${this.domain}/api/v1/users/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await this.handleResponse(response);
  }

  async getMyAppointments(): Promise<ApiResponse<UnifiedDto>> {
    const token = checkNotNull(this.token);
    const response = await fetch(`${this.domain}/api/v1/appointments`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await this.handleResponse(response);
  }

  async getAppointmentProtocol(id: string): Promise<ApiResponse<UnifiedDto>> {
    const token = checkNotNull(this.token);
    const response = await fetch(
      `${this.domain}/api/v1/appointment-protocol?appointment_id=${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return await this.handleResponse(response);
  }

  async deleteAppointment(id: string): Promise<ApiResponse<null>> {
    const token = checkNotNull(this.token);
    const response = await fetch(`${this.domain}/api/v1/appointment/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return await this.handleResponse(response);
  }
}

export const api = new AimsLkApi();
