import { ReactElement, useEffect, useState } from 'react';
import { api } from '../../app-components/network/AimsLkApi';
import { error, sortedByDescending } from '../../app-components/common/Utils';
import { CircularProgress } from '@mui/material';
import { Appointment } from '../../models/Appointment';
import { useNavigate } from 'react-router-dom';
import { AppointmentListItem } from '../components/AppointmentListItem/AppointmentListItem';
import ContentColumn from '../components/ContentColumn/ContentColumn';
import { PageHeader } from '../components/PageHeader/PageHeader';

export default function AppointmentsPage(): ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const dto = await api.getMyAppointments();
      if (dto.type != 'success') error();
      const sortedAppointments = sortedByDescending(
        dto.payload.appointments ?? [],
        (it) => it.id
      );
      setAppointments(sortedAppointments);
      setLoading(false);
    })();
  }, []);

  return (
    <ContentColumn>
      <PageHeader>Список приемов</PageHeader>
      {loading ? (
        <CircularProgress />
      ) : (
        appointments.map((appointment) => {
          return (
            <AppointmentListItem
              key={appointment.id}
              appointment={appointment}
              onClick={() => navigate(`/appointment/${appointment.id}`)}
            />
          );
        })
      )}
    </ContentColumn>
  );
}
