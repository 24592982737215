import React, { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from '../../routes/router';
import {
  LoggedUserContext,
  LoggedUserContextInitializer,
} from './LoggedUserContext';
import { User } from '../../../models/User';

function App() {
  const [loggedUser, setLoggedUser] = useState<User | null>(null);
  return (
    <div className='app'>
      <LoggedUserContext.Provider value={{ loggedUser, setLoggedUser }}>
        <LoggedUserContextInitializer>
          <RouterProvider router={router} />
        </LoggedUserContextInitializer>
      </LoggedUserContext.Provider>
    </div>
  );
}

export default App;
