import './FullscreenCircularProgress.css';
import { CircularProgress } from '@mui/material';
import React from 'react';

export function FullscreenCircularProgress() {
  return (
    <div className='fullscreen-circular-progress'>
      <CircularProgress />
    </div>
  );
}
