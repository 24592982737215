import './LoginPage.css';
import React, { ReactElement, useContext, useState } from 'react';
import { api } from '../../../app-components/network/AimsLkApi';
import { CircularProgress } from '@mui/material';
import {
  LoggedUserContext,
  saveToken,
} from '../../components/App/LoggedUserContext';
import { checkNotNull, error } from '../../../app-components/common/Utils';

export default function LoginPage(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loggedUserContext = useContext(LoggedUserContext);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  async function handleLogin(event: React.FormEvent) {
    event.preventDefault();
    try {
      setIsLoading(true);
      const tokenResponse = await api.fetchAuthToken(username, password);
      if (tokenResponse.type === 'success') {
        const token = tokenResponse.payload;
        api.setToken(token);
        const userInfo = await api.getUserInfo();
        if (userInfo.type != 'success') error();
        const user = checkNotNull(userInfo.payload.users)[0];
        loggedUserContext.setLoggedUser(user);
        saveToken(token);
      } else if (tokenResponse.type === 'error') {
        setErrorMessage(tokenResponse.message);
      } else {
        error();
      }
    } catch (error) {
      console.log(`Произошла ошибка при загрузке данных: ${error}`);
    }
    setIsLoading(false);
  }

  return (
    <main className='welcome-page'>
      {isLoading && <CircularProgress />}
      {/*// как реагируем, если загрузка завершилась, но есть ошибки todo*/}
      {!isLoading && (
        <>
          <h1 className='welcome-page__title'>Вход в Личный кабинет</h1>
          <form className='welcome-page__form' onSubmit={handleLogin}>
            <div className='welcome-page__input-container'>
              <label className='welcome-page__label'>
                <span className='welcome-page__label-text'>Логин</span>
                <input
                  type='text'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className='welcome-page__input'
                  required
                  minLength={3}
                  maxLength={20}
                />
              </label>
              <label className='welcome-page__label'>
                <span className='welcome-page__label-text'>Пароль</span>
                <input
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='welcome-page__input'
                  required
                  minLength={6}
                />
              </label>
              {errorMessage && (
                <p className='welcome-page__error-message'>{errorMessage}</p>
              )}
            </div>
            <button type='submit' className='welcome-page__button'>
              Войти
            </button>
          </form>
        </>
      )}
    </main>
  );
}
