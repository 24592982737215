import React, {
  Context,
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { User } from '../../../models/User';
import { api } from '../../../app-components/network/AimsLkApi';
import { checkNotNull, error } from '../../../app-components/common/Utils';
import { FullscreenCircularProgress } from '../FullscreenCircularProgress/FullscreenCircularProgress';

type LoggedUserContextType = {
  loggedUser: User | null;
  setLoggedUser: (user: User | null) => void;
};

export const LoggedUserContext: Context<LoggedUserContextType> = createContext({
  loggedUser: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoggedUser: () => {},
} as LoggedUserContextType);

export function LoggedUserContextInitializer(props: {
  children: ReactElement;
}): ReactElement {
  const loggedUserContext = useContext(LoggedUserContext);
  const [inited, setInited] = useState(false);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem('token');
      if (token == null) {
        setInited(true);
        return;
      }

      api.setToken(token);
      const userInfoDto = await api.getUserInfo();
      if (userInfoDto.type != 'success') error();
      const me = checkNotNull(userInfoDto.payload.users)[0];
      loggedUserContext.setLoggedUser(me);
      setInited(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return inited ? props.children : <FullscreenCircularProgress />;
}

export function saveToken(token: string) {
  localStorage.setItem('token', token);
}
